

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCmsCat7 extends Vue {
  @Prop() private cmsCategoryChilds!: object;
  fileList: object[] = [];
  curLang: any;
  bookLinks: string[] =[
    '/publications/HKF&DA_design1/mobile/index.html', '/publications/HKF&DA_60th_booklet_lo/mobile/index.html', '/publications/HKF&DA_booklet26092016/mobile/index.html'
  ];

  getFile () {
    this.$Api.file.getFile(22).then((result) => {
      console.log(result.FileList, '商會刊物');
      if (result.FileList) {
        this.fileList = result.FileList;
      }
      this.curLang = localStorage.getItem('locale');
    }).catch((error) => {
      console.log(error, 'error');
    });
  }
  created () {
    this.getFile();
  }
}
